import {Component} from '@angular/core'
import {FormBuilder, Validators} from '@angular/forms'
import {FeatureStore} from '@/lib/app/stores/feature.store'
import {LANGUAGE_CODES, LOCALE_NAME_VALUE_MAP} from '@/lib/app/models/user-invitation.interface'

@Component({
  selector: 'org-case-member-form-group',
  templateUrl: './case-member-form-group.component.html',
})
export class CaseMemberFormGroupComponent {
  form = this._fb.group({
    first_name: ['', Validators.required],
    last_name: ['', Validators.required],
    email: ['', Validators.email],
    phone: ['', Validators.minLength(10)],
    send_sms: [true],
    preferred_locale: LANGUAGE_CODES.ENGLISH,
  })

  constructor(
    private _fb: FormBuilder,
    private _features: FeatureStore,
  ) {}

  get isEmailRequired(): boolean {
    if (this._features.isSmsEnabled) {
      // if sms is enabled, then email is only required when we don't have a phone
      return !this.form.value.phone
    }

    // otherwise it's required
    return true
  }

  get isPhoneRequired(): boolean {
    if (this._features.isSmsEnabled) {
      // is sms is enabled, then email is only required when we don't have an email
      return !this.form.value.email
    }

    // otherwise it's optional
    return false
  }

  get shouldShowSmsCheckbox() {
    return this._features.isSmsEnabled && this.form.value.phone && this.form.value.email
  }

  get isMultilingualEnabled() {
    return this._features.isMultilingualEnabled
  }

  public LOCALE_NAME_VALUE_MAP = LOCALE_NAME_VALUE_MAP
}

