import {IRole} from './role.interface'
import {ILocation} from '@/org/app/models/location.interface'
import {Abilities} from '@/lib/app/models/abilities.type'
import {InvitationStatuses} from '@/lib/app/models/case-invitation.interface'

export interface IUserInvitation {
  id: number
  role_id: IRole['id']
  status: InvitationStatuses
  email: string
  meta: IUserInvitationMeta
  name: string
  __abilities: Abilities
}

export interface IUserInvitationMeta {
  first_name?: string
  last_name?: string
  phone?: string
  location_id?: ILocation['id']
}

// These need to be kept in sync with the enduser languages found here:
// client/enduser/src/library/constants/languages.ts
export enum LANGUAGE_CODES {
  ENGLISH = 'en',
  SPANISH = 'es',
}
export const LANGUAGE_CODE_TO_NAME_MAP = {
  [LANGUAGE_CODES.ENGLISH]: 'English',
  [LANGUAGE_CODES.SPANISH]: 'Spanish',
  // fr: "Français"
}

export const LOCALE_NAME_VALUE_MAP = [
  {id: LANGUAGE_CODES.ENGLISH, label: LANGUAGE_CODE_TO_NAME_MAP[LANGUAGE_CODES.ENGLISH]},
  {id: LANGUAGE_CODES.SPANISH, label: LANGUAGE_CODE_TO_NAME_MAP[LANGUAGE_CODES.SPANISH]},
]
