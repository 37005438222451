<div class="row">
  <div *ngIf="isLoaded()">
    <div class="d-flex justify-content-between align-items-baseline mb-3">
      <h2>Cases</h2>
      @if(cases.length > 0 || hasFilters) {
        <div class="form-check">
          <label class="form-check-label">
            <input
              type="checkbox"
              [(ngModel)]="shouldHideDemoCases"
              [ngModelOptions]="{standalone: true}"
              class="form-check-input" />
            Hide demo cases
          </label>
        </div>
      }
    </div>

    @if (features.isPrePlanningEnabled) {
      <ul class="nav nav-tabs tabbed-card-nav" id="caseTab" role="tablist">
        <li class="nav-item" role="presentation">
          <button
            class="nav-link"
            [ngClass]="{active: selectedTabCaseStatus === CaseStatus.STATUS_AFTER_CARE}"
            (click)="onSelectTab(CaseStatus.STATUS_AFTER_CARE)"
            data-bs-toggle="tab"
            type="button"
            role="tab">
            <span class="d-inline d-md-none">
              EA
            </span>
            <span class="d-none d-md-inline">
              Executor Assistant
            </span>
          </button>
        </li>
        <li class="nav-item" role="presentation">
          <button
            class="nav-link"
            [ngClass]="{active: selectedTabCaseStatus === CaseStatus.STATUS_PRE_PLANNER}"
            (click)="onSelectTab(CaseStatus.STATUS_PRE_PLANNER)"
            data-bs-toggle="tab"
            type="button"
            role="tab">
            <span class="d-inline d-md-none">
              LP
            </span>
            <span class="d-none d-md-inline">
              Legacy Planner
            </span>
          </button>
        </li>
      </ul>
    }
    <div class="card p-4 mb-3"
         [class.border-top-0]="features.isPrePlanningEnabled"
         [class.rounded-top-0]="features.isPrePlanningEnabled"
    >
      @if (cases.length > 0 || hasFilters) {
        <form
          [formGroup]="filters.form"
          (ngSubmit)="onFiltersSubmit()"
        >
          <div *ngIf="hasFilters || hasSort">
            <span class="me-2">Filters applied</span>
            <button type="button" class="btn btn-link text-muted p-0 align-baseline" (click)="onClearFilterAndSort()">
              Reset
            </button>
          </div>

          <div class="table-responsive">
            <table class="table table-hover">
              <thead>
              <tr class="align-top">
                <th>
                  <div class="mb-2">Contact</div>
                  <input
                    type="text"
                    formControlName="owner_search"
                    class="form-control form-control-sm"
                    [class.active-filter]="filters.hasFilters('owner_search')"
                    style="min-width: 160px"
                    placeholder="Name, email, phone" />
                </th>
                <th [class.d-none]="selectedTabCaseStatus === CaseStatus.STATUS_PRE_PLANNER">
                  <div class="mb-2">Deceased</div>
                  <input
                    type="text"
                    formControlName="name"
                    [class.active-filter]="filters.hasFilters('name')"
                    class="form-control form-control-sm"
                    style="min-width: 120px"
                    placeholder="Filter by name" />
                </th>
                <th>
                  <div class="mb-2">
                    <span [tooltip]="'Name of employee that invited the contact'"> Employee </span>
                  </div>
                  <select
                    class="form-select form-select-sm"
                    [class.active-filter]="filters.hasFilters('handled_by_id')"
                    style="min-width: 130px"
                    formControlName="handled_by_id"
                    (change)="fetchCases()">
                    <option [ngValue]="null">Filter employee</option>
                    <option *ngFor="let u of users" [ngValue]="u.id">
                      {{ u.name }}
                    </option>
                  </select>
                </th>
                <th *ngIf="locations.length">
                  <div class="mb-2">Location</div>
                  <select
                    class="form-select form-select-sm"
                    [class.active-filter]="filters.hasFilters('location_id')"
                    style="min-width: 130px"
                    formControlName="location_id"
                    (change)="fetchCases()">
                    <option [ngValue]="null">Filter location</option>
                    <option *ngFor="let l of locations" [ngValue]="l.id">
                      <lib-location-name [location]="l"/>
                    </option>
                  </select>
                </th>
                <th>
                  <span [tooltip]="'Date on which the contact was invited'"> Invited on </span>
                </th>
                <th>
                  <span [tooltip]="'Date of  sign-up for the case invitation'"> Active since </span>
                </th>
                <th
                  *ngIf="activeOrg.activeOrg.meta.pre_need_questionnaire_uuid"
                  class="text-nowrap"
                  [class.d-none]="selectedTabCaseStatus === CaseStatus.STATUS_PRE_PLANNER"
                >
                  <div class="mb-2">
                    <span [tooltip]="'Approximate timing to reach out regarding pre-need'"> Pre-need request </span>
                  </div>
                  <select
                    (ngModelChange)="onSort($event)"
                    class="form-select form-select-sm"
                    [class.active-filter]="sort.field === 'pre_need_lead_contact_at'"
                    [ngModel]="sort.field ? sort.field + ':' + sort.dir : null"
                    [ngModelOptions]="{standalone: true}">
                    >
                    <option [ngValue]="null">(Sort)</option>
                    <option ngValue="pre_need_lead_contact_at:asc">Chronological</option>
                    <option ngValue="pre_need_lead_contact_at:desc">Reverse chronological</option>
                  </select>
                </th>
                <th class="pe-0">
                  <div style="width: 80px">
                    <div *ngIf="hasFilters || hasSort">
                      <button type="submit" class="btn btn-sm btn-outline-primary align-bottom text-nowrap w-100 px-2">
                        <i class="fas fa-search"></i> Search
                      </button>
                    </div>
                  </div>
                </th>
              </tr>
              </thead>
              <tbody class="small">
              <tr *ngFor="let c of cases" [attr.data-case-id]="c.id">
                <td style="max-width: 15vw" class="text-truncate is-pii">
                  <div class="d-flex align-items-center">
                    @if(caseInvitationService.isStatusBounced(c)) {
                      <a
                        class="btn btn-link px-1 text-danger"
                        [routerLink]="['/cases', c.id, 'edit']"
                        tooltip="Invitation email could not be delivered. Click to see more information.">
                        <i class="fa-solid fa-triangle-exclamation"></i>
                      </a>
                    }
                    @if(caseInvitationService.isStatusDraft(c)) {
                      <a
                        class="btn btn-link px-1 text-primary"
                        [routerLink]="['/cases', c.id, 'edit']"
                        tooltip="Invitation not yet sent. Click to see more information.">
                        <i class="fa fa-triangle-exclamation"></i>
                      </a>
                    }
                    <a class="d-block text-truncate" [routerLink]="['/cases', c.id, 'edit']">
                      {{ c.owner.name }}
                    </a>
                    <lib-is-test-indicator *ngIf="c.is_test" variant="compact" class="ms-2" />
                  </div>
                </td>
                <td
                  style="max-width: 15vw"
                  class="text-truncate is-pii"
                  [class.d-none]="selectedTabCaseStatus === CaseStatus.STATUS_PRE_PLANNER"
                >
                  <abbr *ngIf="c.status === CaseStatus.STATUS_AFTER_CARE" [tooltip]="c.name" tooltipDelay="1000">
                    {{c.name }}
                  </abbr>
                </td>
                <td class="text-nowrap is-pii">
                  <ng-container *ngIf="c.caseHandler">
                    <a *ngIf="auth.can('update', c.caseHandler)" [routerLink]="['/users', c.caseHandler.id, 'edit']">
                      {{ c.caseHandler.name }}
                    </a>
                    <span *ngIf="!auth.can('update', c.caseHandler)">
                      {{ c.caseHandler.name }}
                    </span>
                  </ng-container>
                </td>
                <td *ngIf="locations.length" style="max-width: 15vw">
                  <ng-container *ngIf="c.location">
                    <a
                      class="d-block text-truncate"
                      *ngIf="auth.can('update', c.location)"
                      [routerLink]="['/settings/locations/', c.location.id]">
                      <lib-location-name [location]="c.location"/>
                    </a>
                    <span *ngIf="!auth.can('update', c.location)" class="d-block text-truncate">
                      <lib-location-name [location]="c.location"/>
                    </span>
                  </ng-container>
                </td>
                <td class="text-nowrap">
                  @if(c.referred_at) {
                    <abbr [tooltip]="c.referred_at | date : 'medium'">
                      {{ c.referred_at | date }}
                    </abbr>
                  } @else {
                    <abbr
                      class="text-muted"
                      tooltip="The contact has not been invited to use Cadence yet."
                    >
                      Invite not sent
                    </abbr>
                  }
                </td>

                <td class="text-nowrap">
                  <abbr [tooltip]="c.serviced_at | date : 'medium'">
                    {{ c.serviced_at | date }}
                  </abbr>
                </td>

                <td
                  *ngIf="activeOrg.activeOrg.meta.pre_need_questionnaire_uuid"
                  class="text-nowrap"
                  [class.d-none]="selectedTabCaseStatus === CaseStatus.STATUS_PRE_PLANNER"
                  [class.text-muted]="isPreNeedContactAtExpired(c.pre_need_lead_created_at, c.pre_need_lead_contact_at)"
                  [class.text-decoration-line-through]="
                    isPreNeedContactAtExpired(c.pre_need_lead_created_at, c.pre_need_lead_contact_at)
                  ">
                  <abbr [tooltip]="c.pre_need_lead_contact_at | date">
                    {{c.pre_need_lead_contact_at | date : "MMM d" }}
                  </abbr>
                  <ng-container *ngIf="c.pre_need_lead_contact_until">
                    -
                    <abbr [tooltip]="c.pre_need_lead_contact_until | date">
                      {{c.pre_need_lead_contact_until | date : "MMM d" }}
                    </abbr>
                  </ng-container>
                </td>
                <td>
                  <a class="btn btn-link px-1" [routerLink]="['/cases', c.id, 'edit']">
                    <i class="fas fa-edit"></i>
                  </a>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </form>
        <lib-paginator
          [pagination]="pagination"
          (goToPage)="onSelectPage($event)"
          (nextPage)="onNextPage()"
          (prevPage)="onPrevPage()"></lib-paginator>
        <div class="small d-flex align-items-center text-muted" *ngIf="hasFilters">
          <div>Filters applied</div>
          <button class="btn btn-link btn-sm p-0 text-muted ms-2" (click)="onClearFilterAndSort()">Reset</button>
        </div>
      } @else {
        <div *ngIf="!isFetchingCases" class="text-center">
          <h3 class="mb-4 mt-2">
            Are you ready to invite your first
            @if(features.isPrePlanningEnabled){
              @switch (selectedTabCaseStatus){
                @case(CaseStatus.STATUS_AFTER_CARE) {
                  Executor Assistant
                }
                @case(CaseStatus.STATUS_PRE_PLANNER) {
                  Legacy Planner
                }
              }
            }
            contact?
          </h3>
          <div class="mb-4">
            <a class="btn btn-primary" routerLink="/invite" [queryParams]="{status: selectedTabCaseStatus}">
              <i class="fa fa-plus pe-1"></i>
              Invite contact
            </a>
          </div>
          <div class="img-fluid">
            <img src="assets/cases.png" height="216" width="272" />
          </div>
        </div>
      }
    </div>

  </div>
</div>
