import {Component, EventEmitter, Input, Output, ViewChild} from '@angular/core'
import {InvitationStatuses, ICaseInvitation} from '@/lib/app/models/case-invitation.interface'
import {DatePipe} from '@angular/common'
import {CaseStatus, ICase} from '@/lib/app/models/case.interface'
import {AlertService} from '@/lib/app/services/alert.service'
import {CaseInvitationService} from '@/org/app/services/case-invitation.service'
import {ConfirmService} from '@/lib/app/services/confirm.service'
import {CaseService} from '@/org/app/services/case.service'
import {formatPhone} from '@/lib/app/models/phone.model'
import {filter} from 'lodash'
import {
  CaseMemberFormGroupComponent
} from '@/org/app/components/cases/edit/case-member-form-group/case-member-form-group.component'
import {FeatureStore} from '@/lib/app/stores/feature.store'

@Component({
  selector: 'org-case-client-info',
  templateUrl: './case-client-info.component.html',
})
export class CaseClientInfoComponent {
  @Input() case: ICase
  @Output() updated = new EventEmitter<any>()
  mode: 'view' | 'edit' = 'view'

  @ViewChild('memberFormGroupComponent')
  memberFormGroupComponent: CaseMemberFormGroupComponent

  constructor(
    private _alerts: AlertService,
    private _caseInvitations: CaseInvitationService,
    private _confirm: ConfirmService,
    private _cases: CaseService,
    private _features: FeatureStore,
  ) {}

  get caseOwnerStatus(): {label: string; tooltip: string; click?: any} {
    if (this.isInvitePending(this.case.owner.case_invitation)) {
      return {
        label: 'Invited',
        tooltip: "The contact has been invited to use Cadence.<br><br>(Click to copy the client's invitation link)",
        click: () => {
          this.copyToClipboard(this.case.owner.case_invitation.link)
        },
      }
    } else if (this.isInviteAccepted(this.case.owner.case_invitation)) {
      // could show more statuses based on the org billing type here
      const datepipe: DatePipe = new DatePipe('en-US')
      const serviced_at = datepipe.transform(this.case.serviced_at, 'medium')
      return {
        label: 'Active',
        tooltip: `The contact has signed up with a Cadence account.<br><br>Active since: ${serviced_at}`,
      }
    }
  }

  get shouldDisableEdit() {
    // if user has paid or has accepted the invite don't allow to edit.
    // In scenario where there was a self sign up user will not have an invitation but will have paid.
    const isSelfSignup = !this.case.owner.case_invitation
    const isInvitationAccepted = this.case.owner.case_invitation?.status === 'accepted'

    if (isSelfSignup) {
      return true
    } else {
      return isInvitationAccepted
    }
  }

  get isAfterCare() {
    return this.case.status === CaseStatus.STATUS_AFTER_CARE || this.case.status === CaseStatus.STATUS_AFTER_CARE_LOCKED
  }

  toggleDemoStatus = () => {
    this._cases.update(this.case.id, {is_test: this.case.is_test})
  }

  isInvitePending(invitation: ICaseInvitation) {
    return invitation && invitation.status === InvitationStatuses.PENDING
  }

  isInviteDraft(invitation: ICaseInvitation) {
    return invitation && invitation.status === InvitationStatuses.DRAFT
  }

  isInviteAccepted(invitation: ICaseInvitation) {
    return invitation && invitation.status === InvitationStatuses.ACCEPTED
  }

  copyToClipboard(text: string) {
    navigator.clipboard.writeText(text)
    this._alerts.success('Copied to clipboard')
  }

  onEdit() {
    this.mode = 'edit'
    // memberFormComponent is rendered based on mode === 'edit'
    // i need to use setTimeout here so memberFormGroupComponent is rendered before trying to patch the form in it
    setTimeout(() => this.memberFormGroupComponent.form.patchValue(this.case.owner.case_invitation))
  }

  async onSubmit() {
    if (!this.memberFormGroupComponent.form.valid) {
      return
    }

    // determine if any phone or email invites will need to be resent as a result of this change
    // if either phone or email invite needs to be resent, pop a confirmation modal before proceeding.
    const inviteStatus = this.case.owner.case_invitation.status
    const email = this.memberFormGroupComponent.form.get('email').value
    const hasEmailChanged = email != this.case.owner.case_invitation.email
    const phone = this.memberFormGroupComponent.form.get('phone').value
    const sendSms = this.memberFormGroupComponent.form.get('send_sms').value
    const hasPhoneChanged = phone != this.case.owner.case_invitation.phone
    const hasSendSmsChanged = sendSms != this.case.owner.case_invitation.send_sms
    const canResendInvite = inviteStatus === 'pending' || inviteStatus === 'bounced'
    const shouldResendEmail = email && hasEmailChanged
    const shouldResendPhone = this._features.isSmsEnabled && ((sendSms && hasPhoneChanged) || hasSendSmsChanged)
    if (canResendInvite && (shouldResendEmail || shouldResendPhone)) {
      const emailMessage = email ? `<p>This will send a new email to <strong>${email}</strong> with an invitation to this Case.</p>` : ''
      const phoneMessage = this._features.isSmsEnabled && phone && sendSms ? `<p>This will send a new text message to <strong>${formatPhone(phone)}</strong> with an invitation to this Case.</p>` : ''
      const msg = `
        ${emailMessage} 
        ${phoneMessage}
        <p>Continue?</p>`
      if (!(await this._confirm.confirm(msg))) {
        return
      }
    }
    try {
      this.memberFormGroupComponent.form.patchValue(
        await this._caseInvitations.update(this.case.id, this.case.owner.case_invitation.id, this.memberFormGroupComponent.form.value),
      )
    } catch (e) {
      this._alerts.error(e)
      return
    }
    this._alerts.success('Success')
    this.mode = 'view'
    this.updated.emit()
  }

  onCancelEdit() {
    this.mode = 'view'
    this.memberFormGroupComponent.form.patchValue(this.case.owner.case_invitation)
  }

  async onBookMeeting() {
    const confirmed = await this._confirm.confirm(
      'This will open a page where you can book a meeting with a Cadence advisor on behalf of the contact.<br><br>Proceed?',
    )
    if (!confirmed) {
      return
    }
    window.open(this.case.meeting_link, '_blank')
  }

  protected readonly InvitationStatuses = InvitationStatuses

  async onSendInvitation() {
    const notifications = [
      this.case.owner.email ? `<strong>${this.case.owner.email}</strong>` : '',
      this.case.owner.case_invitation.phone && this.case.owner.case_invitation.send_sms
        ? `<strong>${formatPhone(this.case.owner.case_invitation.phone)}</strong>`
        : '',
    ]
    const confirmed = await this._confirm.confirm(
      `This will send the invitation to ${filter(notifications).join(
        ' and ',
      )} with instructions on how to get started with Cadence.`,
    )
    if (!confirmed) {
      return
    }

    try {
      await this._caseInvitations.send(this.case.id, this.case.owner.case_invitation.id)
    } catch (e) {
      this._alerts.error(e)
      return
    }

    this._alerts.success('Invitation sent')
    this.updated.emit()
  }

  async resendInitialCaseInvitation() {
    const notifications = [
      this.case.owner.email ? `<strong>${this.case.owner.email}</strong>` : '',
      this.case.owner.case_invitation.phone && this.case.owner.case_invitation.send_sms
        ? `<strong>${formatPhone(this.case.owner.case_invitation.phone)}</strong>`
        : '',
    ]
    const confirmed = await this._confirm.confirm(
      `This will resend the invitation to ${filter(notifications).join(
        ' and ',
      )} with instructions on how to get started with Cadence.`,
    )
    if (!confirmed) {
      return
    }

    try {
      await this._caseInvitations.resendInitialInvitation(this.case.id, this.case.owner.case_invitation.id)
    } catch (e) {
      this._alerts.error(e)
      return
    }

    this._alerts.success('Invitation sent')
    this.updated.emit()
  }
}
