<div [formGroup]="form">
  <div class="mb-2">
    <label class="form-label">Name <lib-required-indicator /></label>
    <input
      type="text"
      id="first_name"
      name="first_name"
      formControlName="first_name"
      class="form-control"
      placeholder="First name" />
    <div class="invalid-feedback">Please provide a first name.</div>
  </div>
  <div class="mb-3">
    <input
      type="text"
      class="form-control"
      id="last_name"
      name="last_name"
      formControlName="last_name"
      placeholder="Last name" />
    <div class="invalid-feedback">Please provide a last name.</div>
  </div>

  <div class="mb-3">
    <label for="email" class="form-label">
      Email
      <lib-required-indicator *ngIf="isEmailRequired" />
    </label>

    <input
      type="email"
      class="form-control"
      id="email"
      name="email"
      formControlName="email" />

    <div class="invalid-feedback">Please provide a valid email.</div>
  </div>
  <div class="mb-3">
    <label for="phone" class="form-label">
      Phone number
      <lib-required-indicator *ngIf="isPhoneRequired" />
    </label>
    <input
      type="tel"
      class="form-control form-control-short"
      id="phone"
      name="phone"
      mask="(000) 000-0000"
      formControlName="phone" />
    <div class="invalid-feedback">Please provide a valid phone number.</div>
  </div>
  <div *ngIf="shouldShowSmsCheckbox" class="mb-3">
    <div class="form-check">
      <label class="form-check-label">
        <input type="checkbox" formControlName="send_sms" class="form-check-input" />
        Send invitation to phone
      </label>
      <div *ngIf="form.value.send_sms" class="form-text">Send a text message with a link to sign up.</div>
    </div>
  </div>
  <div class="mb-2" *ngIf="isMultilingualEnabled">
    <label class="form-label">Preferred language </label>
    <select class="form-select" id="preferred_locale" formControlName="preferred_locale">
      <option *ngFor="let locale of LOCALE_NAME_VALUE_MAP" [ngValue]="locale.id">
        {{ locale.label }}
      </option>
    </select>
  </div>
</div>
